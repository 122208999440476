var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Cập nhật nhanh các khoản cho lớp:  " + _vm.className,
            visible: _vm.dialogVisible,
            width: "95%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "kidsPackageForKidsRef",
              attrs: {
                "default-expand-all": "",
                data: _vm.responseDataList,
                "empty-text": _vm.textTable,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "1" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.fnKidsPackageList.length > 0
                          ? _c(
                              "el-table",
                              {
                                ref: "kidsPackageForKidsRef1",
                                staticStyle: { "border-radius": "0" },
                                attrs: {
                                  data: scope.row.fnKidsPackageList,
                                  "empty-text": _vm.textTable,
                                  "element-loading-spinner": "el-icon-loading",
                                  "element-loading-background":
                                    "rgba(255,255,255, 0)",
                                  "highlight-current-row": "",
                                  "cell-style": _vm.tableRowStyleChildren,
                                  "header-cell-style":
                                    _vm.tableHeaderChidrenColor,
                                  border: "",
                                },
                                on: {
                                  "selection-change": _vm.handleSelectionChange,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    fixed: "",
                                    type: "index",
                                    label: "STT",
                                    width: "50",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    fixed: "",
                                    label: "Tên khoản",
                                    prop: "fnPackage.name",
                                    "min-width": "140",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    fixed: "",
                                    label: "Trạng thái",
                                    align: "center",
                                    width: "95",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.paid == 0
                                              ? _c("span", [
                                                  _vm._v("Chưa đóng"),
                                                ])
                                              : scope.row.paid < scope.row.money
                                              ? _c("span", [_vm._v("Thiếu")])
                                              : _c("span", [_vm._v("Đủ")]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Khoản đính kèm",
                                    align: "center",
                                    width: "125",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row
                                            .fnPackageKidsExtend != null
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Khoản đính kèm: " +
                                                        scope.row
                                                          .fnPackageKidsExtend
                                                          .name,
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-check",
                                                      style: scope.row
                                                        .fnPackageKidsExtend
                                                        .active
                                                        ? "margin-left: 10px; color: #409eff"
                                                        : "margin-left: 10px; color: red",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "Loại",
                                    "min-width": "70",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.fnPackage.category == "in"
                                              ? _c("span", [_vm._v("Thu")])
                                              : scope.row.fnPackage.category ==
                                                "out"
                                              ? _c("span", [_vm._v("Chi")])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "right",
                                    label: "Đơn giá sau giảm",
                                    "min-width": "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.discount
                                              ? _c("span", [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatCurrencyNew"
                                                          )(
                                                            scope.row
                                                              .discountPrice
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ])
                                              : _c("span", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatCurrencyNew"
                                                        )(scope.row.price)
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "calculateNumber",
                                    align: "center",
                                    label: "Số dự toán",
                                    "min-width": "90",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "usedNumber",
                                    align: "center",
                                    label: "Số sử dụng",
                                    "min-width": "90",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.approved ||
                                            scope.row.locked ||
                                            !_vm.checkPermission([
                                              "fees_approved_useNumber",
                                            ])
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row.usedNumber)
                                                  ),
                                                ])
                                              : _c(
                                                  "span",
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "60px",
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        size: "small",
                                                        min: 0,
                                                        max: 10000,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.usedNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "usedNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.usedNumber",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Tiền phải trả",
                                    align: "right",
                                    "min-width": "130",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatCurrencyNew")(
                                                    scope.row.money
                                                  )
                                                )
                                              ),
                                            ]),
                                            scope.row.fnPackageKidsExtend !=
                                              null &&
                                            scope.row.fnPackageKidsExtend.active
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        scope.row.moneyExtend >
                                                        0
                                                          ? "Số tiền tăng: " +
                                                            scope.row
                                                              .moneyExtend +
                                                            " đ"
                                                          : "Số tiền giảm: " +
                                                            scope.row
                                                              .moneyExtend +
                                                            " đ",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    scope.row.moneyExtend > 0
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-top",
                                                          staticStyle: {
                                                            color: "#409eff",
                                                            "font-size": "18px",
                                                          },
                                                        })
                                                      : scope.row.moneyExtend <
                                                        0
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-bottom",
                                                          staticStyle: {
                                                            color: "#f56c6c",
                                                            "font-size": "18px",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Tiền đã trả",
                                    align: "right",
                                    "min-width": "110",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatCurrencyNew")(
                                                    scope.row.paid
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    fixed: "right",
                                    "min-width": "65",
                                    align: "center",
                                    label: "Duyệt",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-checkbox", {
                                              attrs: {
                                                disabled:
                                                  scope.row.showNumber == 0 ||
                                                  scope.row.price == 0 ||
                                                  scope.row.usedNumber !=
                                                    scope.row.showNumber ||
                                                  scope.row.locked ||
                                                  scope.row.paid > 0 ||
                                                  !_vm.checkPermission([
                                                    "fees_approved_approved",
                                                  ])
                                                    ? true
                                                    : false,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.approvedKidsPackageOneMethod(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.approved,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "approved",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.approved",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    fixed: "right",
                                    "min-width": "65",
                                    align: "center",
                                    label: "Khóa",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-checkbox", {
                                              attrs: {
                                                disabled: !_vm.checkPermission([
                                                  "fees_approved_locked",
                                                ]),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.lockedKidsPackageMethod(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.locked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "locked",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.locked",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Tác vụ",
                                    fixed: "right",
                                    width: "170",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.approved ||
                                            scope.row.locked ||
                                            scope.row.paid > 0 ||
                                            scope.row.price == 0 ||
                                            !_vm.checkPermission([
                                              "fees_approved_useNumber",
                                            ])
                                              ? _c("span", [
                                                  scope.row.paid > 0
                                                    ? _c("span", [
                                                        _vm._v("Không thể sửa"),
                                                      ])
                                                    : scope.row.locked
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Đã khóa, có thể sửa"
                                                        ),
                                                      ])
                                                    : scope.row.approved
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Đã duyệt, có thể sửa"
                                                        ),
                                                      ])
                                                    : scope.row.price == 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Đơn giá bằng 0"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _c(
                                                  "span",
                                                  [
                                                    scope.row.showNumber ==
                                                    scope.row.calculateNumber
                                                      ? _c("el-button", {
                                                          attrs: {
                                                            disabled: "",
                                                            type: "primary",
                                                            size: "mini",
                                                            icon: "el-icon-d-arrow-right",
                                                          },
                                                        })
                                                      : _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Sử dụng số dự toán",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c("el-button", {
                                                              attrs: {
                                                                type: "primary",
                                                                size: "mini",
                                                                icon: "el-icon-d-arrow-right",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.transferNumberOneMethod(
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                    scope.row.usedNumber != 0 &&
                                                    scope.row.usedNumber ==
                                                      scope.row.showNumber
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              disabled: "",
                                                              size: "mini",
                                                              type: "success",
                                                            },
                                                          },
                                                          [_vm._v("Đã lưu")]
                                                        )
                                                      : _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "mini",
                                                              type: "success",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.saveUsedNumberOneMethod(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Lưu số")]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "200",
                  prop: "fullName",
                  label: "Họ tên",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Ngày sinh",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.birthDay))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "130", label: "Còn lại thu" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "high-light" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrencyNew")(
                                scope.row.totalMoneyRemainIn
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "130", label: "Còn lại chi" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "high-light" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrencyNew")(
                                scope.row.totalMoneyRemainOut
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "130", label: "Tổng thu" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrencyNew")(
                                scope.row.totalMoneyIn
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "130", label: "Tổng chi" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrencyNew")(
                                scope.row.totalMoneyOut
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "approvedNumber",
                  width: "100",
                  label: "Đã duyệt",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "lockedNumber",
                  width: "100",
                  label: "Đã khóa",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "110", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.fnKidsPackageList.length > 0
                          ? _c(
                              "span",
                              [
                                !scope.row.expandRow
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toogleExpandDetailDate(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Thu nhỏ")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toogleExpandDetailDate(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Mở rộng")]
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.checkPermission(["fees_approved_useNumber"])
                ? _c(
                    "el-button",
                    {
                      staticClass: "button-bottom",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.transferNumberManyMethod()
                        },
                      },
                    },
                    [_vm._v("\n        Sử dụng số dự toán\n      ")]
                  )
                : _vm._e(),
              _vm.checkPermission(["fees_approved_useNumber"])
                ? _c(
                    "el-button",
                    {
                      staticClass: "button-bottom",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.saveUseNumberManyMethod()
                        },
                      },
                    },
                    [_vm._v("\n        Lưu số\n      ")]
                  )
                : _vm._e(),
              _vm.checkPermission(["fees_approved_approved"]) ||
              _vm.checkPermission(["fees_approved_locked"])
                ? _c(
                    "el-dropdown",
                    { on: { command: _vm.handleCommandMethod } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-bottom",
                          attrs: { type: "success" },
                        },
                        [
                          _vm._v("\n          Tác vụ\n          "),
                          _c("i", { staticClass: "el-icon-caret-bottom" }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _vm.checkPermission(["fees_approved_approved"])
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "approvedCommand" } },
                                [_vm._v("Duyệt")]
                              )
                            : _vm._e(),
                          _vm.checkPermission(["fees_approved_approved"])
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "unApprovedCommand" } },
                                [_vm._v("Bỏ duyệt")]
                              )
                            : _vm._e(),
                          _vm.checkPermission(["fees_approved_locked"])
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "lockedCommand" } },
                                [_vm._v("Khóa")]
                              )
                            : _vm._e(),
                          _vm.checkPermission(["fees_approved_locked"])
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "unLockedCommand" } },
                                [_vm._v("Bỏ khóa")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "button-bottom",
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }